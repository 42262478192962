  export const modulesRoles = [
    {
      type: 'link',
      text: 'Dashboard',
      iconLeft: '<i class="mdi mdi-view-dashboard"></i>',
      subMenuOptions: [
        {
          type: 'link',
          text: 'Estadísticas',
          path: { name: 'statistics' },
          iconLeft: '<i class="mdi mdi-circle-medium"></i>',
          role: ['Administrador', 'General'],

        },

      ],
    },
    {
      type: 'link',
      text: 'Facturas ',
      iconLeft: '<i class="mdi mdi-folder-table"></i>',
      subMenuOptions: [
        {
          type: 'link',
          text: 'Reporte',
          path: { name: 'reportInvoices' },
          iconLeft: '<i class="mdi mdi-circle-medium"></i>',
          role: ['Administrador', 'General'],

        },
      ],
    },
    {
      type: 'link',
      text: 'Administración',
      iconLeft: '<i class="mdi mdi-cogs"></i>',
      subMenuOptions: [
        {
          type: 'link',
          text: 'Clientes',
          path: { name: 'clientsConfig' },
          iconLeft: '<i class="mdi mdi-circle-medium"></i>',
          role: ['Administrador'],

        },
        {
          type: 'link',
          text: 'Firmas Electronicas',
          path: { name: 'firmasConfig' },
          iconLeft: '<i class="mdi mdi-circle-medium"></i>',
          role: ['Administrador', 'General-Admin'],

        },
        {
          type: 'link',
          text: 'Usuarios',
          path: { name: 'userConfig' },
          iconLeft: '<i class="mdi mdi-circle-medium"></i>',
          role: ['Administrador'],

        },
        {
          type: 'link',
          text: 'Logs',
          path: { name: 'Logs' },
          iconLeft: '<i class="mdi mdi-circle-medium"></i>',
          role: ['Administrador'],

        },
      ],
    },
  ]
